.button {
  padding: 0.9rem 3rem;
  border: 1px solid var(--main-text-color);
  border-radius: 0.7rem;
  background-color: transparent;
  color: var(--main-text-color);
  font-size: 2rem;
  font-weight: 200;
  border: 1px solid var(--main-text-color);
  cursor: pointer;
  transition: 0.2s;
}

.button.red {
  border: 1px solid transparent;
  background-color: var(--accent-color-red);
}

.button.blue {
  border: 1px solid transparent;
  background-color: var(--accent-color-green);
}

.button:hover {
  -webkit-filter: brightness(1.2);
  filter: brightness(1.2);
}
