.movie_card {
  position: relative;
}

.movie {
  z-index: 0;
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  color: var(--main-text-color);
  background-color: var(--card-color);
  border-radius: 1.1rem;
  cursor: pointer;
}

.movie::before {
  z-index: -1;
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  width: 80%;
  box-shadow: 0 0 2.5rem 0.8rem rgb(0, 0, 0);
}

.movie_info {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-image: linear-gradient(to top, black 15%, rgba(0, 0, 0, 0));
  border-radius: 1.1rem;
  opacity: 0;
  transition: 0.2s;
}

.movie_info h3 {
  font-size: 2rem;
  text-align: center;
  color: var(--favourite-color);
}

.movie_info p {
  font-size: 1.5rem;
}

.image_container {
  overflow: hidden;
  border-radius: 1.1rem;
}

.movie:hover > .movie_info {
  opacity: 1;
  outline: 1px solid var(--main-text-color);
}

.movie img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 1.1rem;
}

.favourite {
  z-index: 2;
  position: absolute;
  display: flex;
  top: 0.6rem;
  right: 0.6rem;
  height: 4rem;
  width: 4rem;
  font-size: 1.8rem;
  align-items: center;
  justify-content: center;
  color: var(--main-text-color);
  background-color: transparent;
  border: none;
  border-radius: 50%;
  transition: 0.2s;
  cursor: pointer;
}

.favourite:hover {
  color: var(--accent-color-red);
}

.rating {
  display: flex;
  margin-bottom: 1rem;
  height: 2rem;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;
}

.rating .icon {
  color: var(--favourite-color);
}
