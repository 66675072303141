.backdrop {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.04;
}

.backdrop img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.backdrop::after {
  z-index: 1;
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 20%;
  width: 100%;
  background-image: linear-gradient(
    to top,
    var(--background-color),
    rgba(0, 0, 0, 0)
  );
}

.container {
  display: flex;
  margin-inline: auto;
  margin-top: 23rem;
  padding-inline: var(--main-padding);
  width: 100%;
  max-width: 1500px;
}

.left_info {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.poster {
  position: relative;
  margin-bottom: 2rem;
  height: calc(17vw * 1.5);
  width: 17vw;
  border-radius: 1.1rem;
  background-color: var(--card-color);
}

.poster::before {
  z-index: -1;
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -0.5rem);
  height: 0;
  width: 80%;
  box-shadow: 0 0 2.8rem 1.5rem rgb(0, 0, 0);
}

.poster img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 1.1rem;
}

.right_info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4rem;
  padding-left: 4rem;
}

.top_info_row {
  display: flex;
  gap: 2rem;
}

.title {
  display: flex;
  flex-direction: column;
}

.movie_title {
  font-size: 3rem;
  font-weight: 600;
}

.title_image {
  height: 5vw;
  width: 20vw;
  padding-bottom: 1rem;
}

.title_image img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left;
  object-position: left;
}

.movie_title span {
  margin-left: 1rem;
  font-weight: 100;
}

.movie_rating_row {
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
  align-items: center;
  font-size: 2rem;
  font-weight: 100;
}

.rating {
  display: block;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 2.4rem;
  border: 1px solid rgba(255, 255, 255, 0.438);
  border-radius: 0.5rem;
}

.rating .icon {
  margin-right: 1rem;
  color: var(--favourite-color);
}

.info_row h3 {
  font-size: 2rem;
}

.info_row p,
.info_row ul {
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: 100;
}

.info_row ul {
  list-style: none;
}

.info_row ul li::after {
  content: ', ';
}

.info_row ul li:last-child::after {
  content: '';
}

.providers {
  display: flex;
  list-style: none;
  gap: 1rem;
}

.providers li {
  height: 6rem;
}

.providers li img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 1.1rem;
}

.credits_list {
  position: relative;
  display: flex;
  height: 220px;
  flex-wrap: wrap;
  list-style: none;
  gap: 1rem;
  row-gap: 4rem;
  overflow-y: scroll;
}
