.background {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-image: url("../../assets/gradient-bg.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.background_blur {
  height: 100%;
  width: 100%;
  -webkit-backdrop-filter: blur(180px);
  backdrop-filter: blur(100px);
}
