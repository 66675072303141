.content_wrapper {
  padding-top: 12rem;
}

.section_title {
  display: flex;
  margin-block: 5rem;
  padding-inline: var(--main-padding);
}

.help_window {
  display: flex;
  margin-top: 4rem;
  margin-inline: auto;
  padding: 2rem;
  width: min(70vw, 1180px);
  min-height: 250px;
  height: 500px;
  background-color: rgba(255, 255, 255, 0.047);
  border-radius: 2.5rem;
  overflow: hidden;
}

.menu {
  padding: 2rem;
  padding-right: 4rem;
  width: 35%;
  border-right: 1px solid var(--main-text-color);
}

.content {
  padding: 2rem;
  margin-left: 2rem;
  height: 100%;
  width: 100%;
  border-radius: 1.2rem;
  box-shadow: inset 0px 0.3rem 0.4rem 0px rgba(0, 0, 0, 0.171);
  background-color: rgba(0, 0, 0, 0.082);
  overflow-y: scroll;
}

.menu ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
}

.menu button {
  height: 5rem;
  width: 100%;
  padding-right: 2rem;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: right;
  color: var(--main-text-color);
  background: none;
  border: none;
  border-radius: 1.1rem;
  cursor: pointer;
}

.menu button:hover {
  background-color: rgba(255, 255, 255, 0.055);
}

.menu button.active {
  background-color: rgba(0, 0, 0, 0.102);
}

.help_section {
  display: none;
  flex-direction: column;
  gap: 1rem;
}

.help_section.active {
  display: flex;
}

.help_section h3 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.help_section p {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.help_section p span {
  display: inline-block;
  margin-bottom: 1rem;
  text-indent: 5rem;
  text-align: justify;
}
