.title {
  min-width: 30rem;
  font-size: 3.1rem;
  font-weight: 100;
  text-align: left;
  text-transform: uppercase;
  color: var(--main-text-color);
}

.h1 {
  font-size: 4.1rem;
}

.h2 {
  font-size: 3.1rem;
}

.h3 {
  font-size: 2.5rem;
}

.h4 {
  font-size: 2rem;
}

.h5 {
  font-size: 1.5rem;
}

.h6 {
  font-size: 1rem;
}
