.section {
  position: relative;
  width: 100vw;
  margin-top: 8rem;
  padding-block: 1rem;
}

.section_title {
  margin-bottom: 1.5rem;
  padding-inline: var(--main-padding);
}

.rank {
  padding-block: 1rem 2rem;
  border-radius: 0.6rem;
  overflow-x: scroll;
}
