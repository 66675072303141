.movies ul {
  display: grid;
  grid-template-rows: calc(12vw * 1.5);
  padding-inline: var(--main-padding);
  gap: 2rem;
  list-style: none;
}

.movies ul.fetching {
  opacity: 0.1;
}

.movies ul.standard {
  grid-template-columns: repeat(7, 1fr);
}

.movies ul.horizontal {
  grid-template-columns: repeat(20, 12vw);
}

.no_movies {
  position: absolute;
  width: 100vw;
  top: 50%;
  text-align: center;
  font-size: 2rem;
}

.padded_top {
  padding-top: 23rem;
}

.error {
  z-index: 999;
  position: fixed;
  bottom: 3rem;
  left: var(--main-padding);
  padding-inline: 2rem;
  padding-block: 1rem;
  font-size: 2rem;
  color: var(--main-text-color);
  background-color: var(--accent-color-red);
  border-radius: 0.8rem;
}
