.google_actions {
  padding: 0.7rem;
}

.google_button {
  position: relative;
  display: flex;
  height: 5.5rem;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 200;
  color: var(--main-text-color);
  background: rgba(92, 92, 92, 0.14);
  box-shadow: -0.2rem -0.2rem 0.4rem 0px rgba(255, 255, 255, 0.116),
    0.2rem 0.2rem 0.4rem 0px rgba(0, 0, 0, 0.171);
  border-radius: 0.7rem;
  border: none;
  font-family: 'Noto Sans', sans-serif;
  cursor: pointer;
  transition: 0.2s;
}

.google_button span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.google_button img {
  width: 3rem;
}

.google_button:hover {
  background: rgba(124, 124, 124, 0.14);
}
