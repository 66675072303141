.content_wrapper {
  padding-top: 12rem;
}

.section_title {
  display: flex;
  margin-block: 5rem;
  padding-inline: var(--main-padding);
}

.settings_window {
  display: flex;
  margin-top: 4rem;
  margin-inline: auto;
  padding: 2rem;
  width: min(70vw, 1180px);
  min-height: 30rem;
  height: 18vw;
  background-color: rgba(255, 255, 255, 0.047);
  border-radius: 2.5rem;
}

.menu {
  padding: 2rem;
  padding-right: 4rem;
  width: 35%;
  border-right: 1px solid var(--main-text-color);
}

.content {
  padding: 2rem;
  padding-left: 4rem;
  width: 65%;
}

.menu ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
}

.menu button {
  height: 5rem;
  width: 100%;
  padding-right: 2rem;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: right;
  color: var(--main-text-color);
  background: none;
  border: none;
  border-radius: 1.1rem;
  cursor: pointer;
}

.menu button:hover {
  background-color: rgba(255, 255, 255, 0.055);
}

.menu button.active {
  background-color: rgba(0, 0, 0, 0.102);
}

.settings_section {
  display: none;
  flex-direction: column;
  gap: 1rem;
}

.settings_section.active {
  display: flex;
}
