.filter_item {
  padding: 0.3rem 1.5rem;
  font-size: 1.7rem;
  border: 1px solid var(--main-text-color);
  border-radius: 0.4rem;
  cursor: pointer;
  transition: 0.2s;
}

.filter_item:hover {
  border-color: var(--accent-color-red);
  color: var(--accent-color-red);
}

.filter_item.active {
  color: var(--main-text-color);
  background-color: var(--accent-color-red);
  border-color: var(--accent-color-red);
}
