.content_wrapper {
  display: flex;
  width: min(70vw, 1180px);
  min-height: 100%;
  margin-inline: auto;
  margin-top: 20rem;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  height: 35vw;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.047);
  border-radius: 2.5rem;
}

.sign_up {
  padding-inline: 12.5rem;
  left: 0;
  z-index: 2;
}

.container.active .sign_up {
  transform: translateX(100%);
  opacity: 0;
}

.sign_in {
  padding-inline: 12.5rem;
  left: 0;
  opacity: 0;
  z-index: 1;
}

.container.active .sign_in {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  -webkit-animation: toggle 0.6s;
  animation: toggle 0.6s;
}

@-webkit-keyframes toggle {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }
  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

@keyframes toggle {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }
  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.toggle_container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
  border-radius: 36rem 1.1rem 1.1rem 36rem;
  z-index: 99;
}

.container.active .toggle_container {
  transform: translateX(-100%);
  border-radius: 1.1rem 36rem 36rem 1.1rem;
}

.form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  transition: all 0.6s ease-in-out;
}

.toggle {
  background-color: rgba(255, 255, 255, 0.047);
  height: 100%;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.container.active .toggle {
  transform: translateX(50%);
}

.toggle_panel {
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  top: 0;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.toggle_panel h1 {
  font-size: 4rem;
}

.toggle_panel p {
  font-size: 1.5rem;
}

.toggle_panel a {
  text-decoration: none;
  border-bottom: 1px solid;
  transition: 0.2s;
}

.toggle_panel a.red {
  margin-inline: 1rem;
  color: var(--accent-color-red);
}

.toggle_panel a.blue {
  margin-inline: 1rem;
  color: var(--accent-color-green);
}

.toggle_panel a span {
  margin-inline: 0.5rem;
}

.toggle_panel a:hover {
  -webkit-filter: brightness(1.2);
  filter: brightness(1.2);
}

.toggle_left {
  padding: 5rem 10rem 5rem 5rem;
  text-align: left;
  transform: translateX(-200%);
}

.container.active .toggle_left {
  transform: translateX(0);
}

.toggle_right {
  right: 0;
  padding: 5rem 5rem 5rem 10rem;
  text-align: right;
  transform: translateX(0);
}

.container.active .toggle_right {
  transform: translateX(200%);
}
