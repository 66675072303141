.input_group {
  display: flex;
  width: 100%;
  align-items: center;
}

.input {
  height: 5rem;
  width: 100%;
  padding-inline: 3rem;
  font-size: 1.5rem;
  font-weight: 200;
  color: var(--main-text-color);
  background: rgba(12, 12, 12, 0.14);
  box-shadow: inset 0px 3px 4px 0px rgba(0, 0, 0, 0.171);
  border-radius: 7px;
  border: none;
  font-family: 'Noto Sans', sans-serif;
}

.input:disabled {
  background: none;
  box-shadow: none;
}

.input:focus {
  outline: none;
  background: rgba(131, 131, 131, 0.055);
}

.label {
  font-size: 1.5rem;
  width: 20rem;
}
