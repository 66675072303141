.background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-image: url("../../assets/gradient-bg.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.background_blur {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  -webkit-backdrop-filter: blur(180px);
  backdrop-filter: blur(100px);
}

.main {
  flex-grow: 1;
}

.content_wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error_code {
  font-size: 10rem;
  font-weight: 700;
  padding-inline: 10rem;
  color: var(--accent-color-green);
  border-bottom: 1px solid;
}

.error_message {
  margin-top: 3rem;
  font-size: 2rem;
  text-align: center;
}
