.credits_card {
  display: flex;
  flex-direction: column;
  width: 12rem;
  overflow: hidden;
  color: var(--main-text-color);
}

.image {
  position: relative;
  height: calc(12rem * 1.2);
  width: 12rem;
  font-size: 1.5rem;
  background-color: var(--card-color);
  border-radius: 1.1rem;
}

.image::before {
  z-index: -1;
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  width: 80%;
  box-shadow: 0 0 1rem 0.4rem rgb(0, 0, 0);
}

.image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 1.1rem;
}

.info {
  padding-top: 1rem;
  padding-inline: 0.8rem;
}

.info h3 {
  font-size: 1.5rem;
}

.info p {
  font-size: 1.2rem;
  font-weight: 100;
}
