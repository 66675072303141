.filters_section {
  margin-inline: auto;
  width: auto;
  max-width: var(--max-width);
  overflow: hidden;
}

.filters_section h2 {
  font-size: 3.1rem;
  font-weight: 100;
  text-align: left;
  text-transform: uppercase;
  color: var(--main-text-color);
}

.toggle {
  display: block;
  width: 150px;
  position: relative;
  cursor: pointer;
}

.toggle::after {
  position: absolute;
  content: '';
  top: 1.8rem;
  right: 0px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom: none;
  border-color: #fff transparent transparent transparent;
  transform: rotate(0);
  transition: all 0.2s;
}

.toggle.active::after {
  transform: rotate(180deg);
}

.filters {
  /* padding-top: 1rem; */
  max-height: 0;
  opacity: 0;
  transition: all 0.2s;
}

.filters.active {
  max-height: 500px;
  opacity: 1;
}
