.footer {
  z-index: 99;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 6rem;
  margin-top: 5rem;
  margin-inline: auto;
  padding-inline: var(--main-padding);
  padding-block: 2rem;
  width: 100%;
  max-width: var(--max-width);
  font-size: 1.8rem;
  font-weight: 200;
  color: #979797;
}

.copyrights {
  display: flex;
  gap: 6rem;
}

.credits {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.tmdb_logo img {
  width: 8rem;
  height: 8rem;
  -o-object-fit: contain;
  object-fit: contain;
}

.navigation {
  display: flex;
  gap: 6rem;
  list-style: none;
}

.navigation a {
  color: #979797;
  text-decoration: none;
  transition: 0.2s;
}

.navigation a:hover {
  color: var(--main-text-color);
}
