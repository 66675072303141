.filter {
  margin-bottom: 2rem;
}

.filter h3 {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: normal;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.clear_filter {
  margin-left: 2rem;
  color: var(--main-text-color);
  background: none;
  border: none;
  cursor: pointer;
}

.filters_list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 1rem;
}

.filters_list li.active {
  color: var(--main-text-color);
  background-color: var(--accent-color-red);
  border-color: var(--accent-color-red);
}

.movie_title input {
  padding: 0.5rem 1.5rem;
  width: 20rem;
  font-size: 1.7rem;
  color: var(--main-text-color);
  background-color: transparent;
  border: 1px solid var(--main-text-color);
  border-radius: 0.4rem;
  outline: none;
}

.movie_title input::-webkit-input-placeholder {
  color: #979797;
}

.movie_title input::-moz-placeholder {
  color: #979797;
}

.movie_title input:-ms-input-placeholder {
  color: #979797;
}

.movie_title input::-ms-input-placeholder {
  color: #979797;
}

.movie_title input::placeholder {
  color: #979797;
}
