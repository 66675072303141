.header {
  z-index: 999;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  height: 15rem;
  width: 100vw;
  align-items: center;
  transition: all 0.5s;
}

.scrolled {
  height: 10rem;
  background: rgba(33, 46, 56, 0.521);
  backdrop-filter: blur(19.6px);
  -webkit-backdrop-filter: blur(19.6px);
  -webkit-animation: show_bg 0.5s ease;
  animation: show_bg 0.5s ease;
}

@-webkit-keyframes show_bg {
  from {
    height: 15rem;
    background: rgba(33, 46, 56, 0);
  }

  to {
    height: 10rem;
    background: rgba(33, 46, 56, 0.521);
  }
}

@keyframes show_bg {
  from {
    height: 15rem;
    background: rgba(33, 46, 56, 0);
  }

  to {
    height: 10rem;
    background: rgba(33, 46, 56, 0.521);
  }
}

.nav_wrapper {
  display: flex;
  width: 100%;
  max-width: var(--max-width);
  margin-inline: auto;
  padding-inline: var(--main-padding);
  justify-content: space-between;
}

.nav_wrapper img {
  width: 15rem;
}

.nav_top {
  position: relative;
  display: flex;
  align-items: center;
}

.nav_top ul {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 3rem;
}

.nav_top li {
  font-size: 2rem;
}

.nav_top a {
  text-decoration: none;
  font-size: inherit;
  color: var(--main-text-color);
  transition: 0.2s;
}

.nav_top a:hover,
.nav_top a.active {
  color: var(--accent-color-red);
}

a.button {
  padding: 0.9rem 3rem;
  border: 1px solid var(--main-text-color);
  border-radius: 0.7rem;
}

a.button:hover {
  color: var(--main-text-color);
  border-color: transparent;
  background-color: var(--accent-color-red);
}

/* .user_button.active {
  background-color: var(--card-color);
  border-radius: 1px 1px 0 0;
  box-shadow: 0 0 0 2rem var(--card-color);
} */

.user_nav {
  position: absolute;
  top: 4.5rem;
  right: 0;
  width: 20rem;
  padding: 2rem;
  background-color: var(--background-color);
  box-shadow: 0.2rem 0.2rem 1rem 0.1rem rgba(0, 0, 0, 0.336);
  opacity: 1;
  -webkit-animation: show_nav 0.2s ease-in;
  animation: show_nav 0.2s ease-in;
}

@-webkit-keyframes show_nav {
  from {
    top: 3.5rem;
    opacity: 0;
  }
  to {
    top: 4.5rem;
    opacity: 1;
  }
}

@keyframes show_nav {
  from {
    top: 3.5rem;
    opacity: 0;
  }
  to {
    top: 4.5rem;
    opacity: 1;
  }
}

.user_nav ul {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
}

.logout_btn {
  margin-top: 2rem;
  color: var(--accent-color-red);
  -webkit-filter: brightness(1.2);
  filter: brightness(1.2);
}

.user_nav a {
  color: inherit;
}

.user_nav a:hover {
  color: var(--accent-color-green);
}

.logout_btn a:hover {
  color: var(--accent-color-red);
  -webkit-filter: brightness(1.2);
  filter: brightness(1.2);
}

.logout_btn span {
  margin-left: 1rem;
}
