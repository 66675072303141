.random_movie {
  position: relative;
  margin-bottom: 2rem;
  height: 100vh;
  width: 100vw;
  background-color: var(--card-color);
  border-radius: 0.5rem;
  overflow: hidden;
}

.random_movie::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 35rem;
  background-image: linear-gradient(
    to bottom,
    var(--background-color),
    rgba(0, 0, 0, 0)
  );
}

.random_movie::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35rem;
  background-image: linear-gradient(
    to top,
    var(--background-color),
    rgba(0, 0, 0, 0)
  );
}

.background_image {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.random_movie h1 {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 13.1rem;
  text-align: center;
  text-transform: uppercase;
  color: rgba(34, 48, 59, 0.384);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

.section_title {
  z-index: 98;
  position: absolute;
  bottom: 7rem;
  left: var(--main-padding);
}

.section_title h2 {
  display: inline-block;
  font-weight: 500;
  font-size: 4.3rem;
}

.movie_title_container {
  display: flex;
  align-items: center;
  gap: 16;
}

.rating {
  display: inline-block;
  margin-left: 2rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 2.4rem;
  border: 1px solid rgba(255, 255, 255, 0.438);
  border-radius: 0.5rem;
}

.rating .icon {
  margin-right: 1rem;
  color: var(--favourite-color);
}

.buttons_container {
  display: flex;
  margin-left: 2rem;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 5rem;
  font-size: 2.5rem;
  background-color: transparent;
  border: none;
  color: var(--main-text-color);
  cursor: pointer;
  border-radius: 50%;
  transition: 0.2s;
}

.button.more {
  transform: rotate(-45deg);
}

.button.more:hover {
  color: var(--accent-color-green);
}

.button.fav:hover {
  color: var(--accent-color-red);
}

.section_title p {
  font-size: 2.6rem;
  font-weight: 200;
}

.streamings {
  display: flex;
  padding-top: 4rem;
  gap: 1rem;
}

.streamings img {
  display: block;
  height: 6rem;
  width: 10rem;
  -o-object-fit: contain;
  object-fit: contain;
}

.movie_actions {
  z-index: 98;
  position: absolute;
  bottom: 7rem;
  right: var(--main-padding);
}

.favourite {
  position: absolute;
  display: flex;
  top: 0.6rem;
  right: 0.6rem;
  height: 2.6rem;
  width: 2.6rem;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.377);
  border-radius: 50%;
}
