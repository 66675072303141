.avatar_container {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.31);
  border-radius: 50%;
}

.avatar_container img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
